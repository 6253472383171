import { DetailsModal, Icon } from '@HometreeEngineering/component-library';
import { Appliance } from 'api/contract/types';
import { InfoExclamation, OpenArrow } from 'assets/pandoraIcons';
import { format } from 'date-fns';
import { mapIconsToAppliances } from 'features/contract/utils/mapIconsToAppliances';
import { useCallback, useEffect, useState } from 'react';
import { formatPrice } from 'utils/formatPrice';
import styles from './index.module.scss';

const Appliances = ({ appliances }: { appliances: Appliance[] }) => {
    const [appliancesList, setAppliancesList] = useState<
        Array<{ type: string; data: Appliance[] }>
    >([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openedAppliance, setOpenedAppliance] = useState<Appliance[]>([]);

    const groupAppliances = useCallback(() => {
        const types: Array<{ type: string; data: Appliance[] }> = [];
        appliances.forEach((a) => {
            if (!types.length || !types.find((t) => t.type === a.type)) {
                types.push({ type: a.type as string, data: [a] });
            } else {
                const indexOfType = types.findIndex((type) => type.type === a.type);
                types[indexOfType] = {
                    type: types[indexOfType].type,
                    data: [...types[indexOfType].data, a],
                };
            }
        });
        return types;
    }, [appliances]);

    const handleOnClick = () => {
        const grouped = groupAppliances();
        if (!isOpen) {
            setAppliancesList(grouped);
        } else {
            setAppliancesList(grouped.slice(0, 5));
        }
        setIsOpen(!isOpen);
    };

    const handleModalOpening = (applianceOpened: Appliance[]) => {
        setIsModalOpen(true);
        setOpenedAppliance(applianceOpened);
    };

    useEffect(() => {
        const grouped = groupAppliances();
        if (appliances.length > 5) {
            setAppliancesList(grouped.slice(0, 5));
        } else {
            setAppliancesList(grouped);
        }
    }, [appliances, groupAppliances]);

    return (
        <div className={styles.container}>
            <p>
                Appliances covered (<strong>{appliances.length}</strong>){' '}
            </p>
            <div className={styles.iconsContainer}>
                {appliancesList.map(
                    (appliance) =>
                        appliance.type && (
                            <div className={styles.iconsList} key={appliance.type}>
                                <Icon icon={mapIconsToAppliances(appliance.type)} />
                                <div
                                    key={`${appliance.type}-description`}
                                    className={styles.appliancesDescription}
                                >
                                    <h5 className={styles.applianceType}>
                                        {appliance.type}
                                        {appliance.data.length > 1
                                            ? ` (${appliance.data.length})`
                                            : null}
                                        <button
                                            onClick={() => handleModalOpening(appliance.data)}
                                            type="button"
                                            className={styles.infoButton}
                                            aria-label="More info"
                                        >
                                            <InfoExclamation />
                                        </button>
                                    </h5>
                                    {isModalOpen && (
                                        <DetailsModal
                                            isOpen={isModalOpen}
                                            setIsOpen={setIsModalOpen}
                                            data={openedAppliance.map((app) => {
                                                const formattedPrice = `${formatPrice(
                                                    app.price_per_month_gbx
                                                )}`;
                                                return {
                                                    make: app.make_name,
                                                    purchaseDate: app.purchase_date
                                                        ? format(
                                                              new Date(app.purchase_date),
                                                              'dd/MM/yyyy'
                                                          )
                                                        : 'NA',
                                                    price: formattedPrice,
                                                    model: app.model_number,
                                                    retailer: app.purchase_retailer,
                                                    id: app.id,
                                                    type: app.type,
                                                    priceDisplay: formattedPrice,
                                                };
                                            })}
                                            mapper={mapIconsToAppliances}
                                            fields={[
                                                { key: 'make', label: 'Make' },
                                                { key: 'purchaseDate', label: 'Purchase Date' },
                                                { key: 'price', label: 'Price' },
                                                { key: 'model', label: 'Model' },
                                                { key: 'retailer', label: 'Retailer' },
                                            ]}
                                        />
                                    )}

                                    {appliance.data.map((ap, index) => (
                                        <>
                                            <p key={ap.id} className={styles.appliancePricing}>
                                                {appliance.data.length > 1 && index === 0 ? (
                                                    <>
                                                        {formatPrice(
                                                            appliance.data.reduce((total, a) => {
                                                                return (
                                                                    total +
                                                                    (a.price_per_month_gbx ?? 0)
                                                                );
                                                            }, 0)
                                                        )}
                                                        <span className={styles.appliancePrice}>
                                                            {' '}
                                                            / total
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {formatPrice(ap.price_per_month_gbx)}
                                                        <span className={styles.appliancePrice}>
                                                            {' '}
                                                            {appliance.data.length > 1
                                                                ? '/ each'
                                                                : '/ month'}
                                                        </span>
                                                    </>
                                                )}
                                            </p>
                                        </>
                                    ))}
                                </div>
                            </div>
                        )
                )}
            </div>
            {appliances.length > 5 ? (
                <div className={styles.additionalIcons}>
                    {isOpen ? (
                        <OpenArrow onClick={handleOnClick} aria-label="open-appliances-arrow" />
                    ) : (
                        <OpenArrow
                            className={styles.rotated}
                            onClick={handleOnClick}
                            aria-label="closed-appliances-arrow"
                        />
                    )}
                    <p>{isOpen ? 'See less' : 'See more'}</p>
                </div>
            ) : null}
        </div>
    );
};

export default Appliances;
